.post-item {
	@include clearfix;

	img {
		width: 50%;
		float: left;
		margin-right: 20px;
		margin-bottom: 10px;

		@media screen and (max-width: 1000px) {
			width: 100%;
			max-width: 580px;
			float: none;
			margin: 0 auto;
		}
	}

	.post-item-content {

		@media screen and (max-width: 1000px) {
			margin-top: 30px;
		}

		&--title {
			h1 {
				font-family: $dosis;
				font-weight: normal;
				font-size: 25px;
			}
		}

		&--date {
			p {
				font-family: $dosis;
				font-size: 14px;
			}
		}

		&--copy {
			margin-top: 20px;

			p {
				font-family: $dosis;
				font-size: 18px;
				margin-bottom: 10px;
			}

			a {
				color: #000;
			}
		}
	}
}

.pagination {
	@include clearfix;

	margin: 40px 0 20px;

	a {
		width: 270px;
		height: 270px;
		display: block;
		text-decoration: none;
		cursor: pointer;
		overflow: hidden;
		float: left;

		&.pag-post--next {
			float: right;
			text-align: right;

			@media screen and (max-width: 580px) {
				float: none;
				margin-top: 20px;
			}
		}

		@media screen and (max-width: 580px) {
			width: 100%;
			height: auto;
			float: none;
			margin: 0 auto;

			img {
				display: none;
			}
		}

		.post {
			display: block;
			position: relative;
			transform: translateY(-100%);
			background-color: rgba(#fff, .8);
			padding: 15px;
			z-index: 2;

			@media screen and (max-width: 580px) {
				transform: translateY(0%);
			}
			
			&--title,
			&--latest {
				p {
					color: #000;
					font-family: $dosis;
					font-size: 16px;
				}
			}

			&--latest {
				margin-bottom: 10px;

				p {
					font-size: 12px;
					text-transform: uppercase;
				}
			}
		}	

		img {
			width: 100%;
			transition: all .5s ease-in-out;
		}

		&:hover {

			img {
				transform: scale(1.1);
			}
		}
	}
}

.comments-share {
	@include clearfix;
	
	/* background-color: #f9f9f9;
	display: block;
	border-top: 1px solid #000;
	border-bottom: 1px solid #000;
	padding: 20px;
	margin: {
		top: 20px;
		bottom: 20px;
	}
	position: relative; */

	.comments-form {
		@include clearfix;

		width: 50%;
		float: left;
		font-family: $dosis;

		@media screen and (max-width: 680px) {
			width: 100%;
			float: none;
		}

		.label {
			width: 80px;
			display: inline-block;
			margin-top: 15px;
			margin-bottom: 5px;

			&--comment {
				vertical-align: top;
			}
		}

		.input,
		.textarea {
			width: 100%;
			border: 1px solid #000;
			outline: none;
			resize: none;
			appearance: none !important;
			background: none;
			padding: 5px;
		}

		.submit {
			width: 100%;
			text-transform: uppercase;
			margin-top: 10px;
			padding: 10px 20px;
			background: none;
			border: 1px solid #000;
			transition: all .3s ease-in-out;

			&:hover {
				color: #fff;
				background-color: #000;
			}
		}
	}

	.sharers {
		@include clearfix;

		/* width: 50%;
		text-align: center;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);

		@media screen and (max-width: 680px) {
			width: 100%;
			transform: translateY(0);
			position: relative;
			top: 0;
			margin-bottom: 20px;
		} */

		.sharer {
			/* margin: 10px; */
			margin: 20px 10px 10px 0;
			display: inline-block;
			font-family: #dosis;
			color: #000;
			text-decoration: none;
			text-align: left;
			border: 1px solid #000;

			&--twitter {
				background-image: url('../img/svg/svg--social-twitter.svg');
				background-repeat: no-repeat;
				padding: 15px 15px 15px 50px;
			}

			&--facebook {
				background-image: url('../img/svg/svg--social-facebook.svg');
				background-repeat: no-repeat;
				padding: 15px 15px 15px 45px;
			}
		}
	}
}

.comments {
	margin-top: 20px;
	font-family: $dosis;

	ul {
		list-style: none;

		li {
			@include clearfix;
			margin-top: 10px;

			.commenter {
				@include clearfix;

				strong {
					display: block;
					padding-top: 22px;
					margin-bottom: 2px;
				}

				p {
					font-style: italic;
					font-size: 13px;
				}

				img {
					float: left;
					margin-right: 10px;
				}
			}

			.comment {
				margin-top: 10px;
				border-bottom: 1px solid #000;
				margin-bottom: 10px;
				padding-bottom: 10px;

				p {
					line-height: 1.4;
				}
			}
		}
	}
}


.split {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 25px;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }

  &--reverse {
    @media screen and (max-width: 1000px) {
      flex-direction: column-reverse;
    }
  }

  &__item {
    width: 100%;

    @media screen and (max-width: 1000px) {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    @media screen and (min-width: 1000px) {
      &--text {
        padding-left: 50px;
      }
    }

    img {
      width: 100%;
      max-width: 100%;
    }
  }
  
  @media screen and (min-width: 1000px) {
    &--reverse {
      .split__item--text {
        padding-right: 50px;
        padding-left: 0;
      }
    }
  }
}