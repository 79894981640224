$dosis: 'Dosis', sans-serif;

.main {
	background-image: url('../img/bg.jpg');
	background-size: 100% auto;
	background-attachment: fixed;
	background-position: top center;
	background-repeat: no-repeat;

	@media screen and (max-width: 663px) and (max-height: 981px) {
		background-size: cover;
	}

	&.white-bg {
		background-image: none;
	}
}

.container {
	@include clearfix;

    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.logo {
	width: 100%;
	max-width: 220px;
	height: 190px;
	background-image: url('../img/svg/svg--logo.svg');
	background-position: center;
	background-repeat: no-repeat;
	margin: 0 auto;
	text-indent: -9999px;

	@media screen and (max-width: 610px) {
		height: 100px;
	}

	&--post {
		height: 150px;
	}
}

.filters,
.latest,
.posts {
	float: left;
	margin: 10px 0;

	ul {
		@include clearfix;

		list-style: none;

		li {
			width: 270px;
			float: left;
			margin: 10px;

			@media screen and (max-width: 610px) {
				margin: 10px 0;
			}

			a {
				width: 270px;
			}

			&:first-child {
				background-color: #fff;
			}
		}
	}
	
	a {
		width: 270px;
		height: 270px;
		display: block;
		text-decoration: none;
		cursor: pointer;
		overflow: hidden;

		.post {
			display: block;
			position: relative;
			transform: translateY(-100%);
			background-color: #fff;
			padding: 15px;
			z-index: 2;
			
			&--title,
			&--latest,
			&--date,
			&--snippet {
				p {
					color: #000;
					font-family: $dosis;
					font-size: 14px;
					text-transform: uppercase;
				}
			}

			&--latest {
				float: left;
			}

			&--date {
				float: right;
				
				p {
					text-transform: none;
				}
			}

			&--snippet {
				margin-top: 5px;

				p {
					text-transform: none;
				}
			}
		}	

		img {
			width: 100%;
			transition: all .5s ease-in-out;
		}

		&:hover {
			img {
				transform: scale(1.1);
			}
		}
	}
}

.filters {
	@include clearfix;

	width: 580px;

	@media screen and (max-width: 1199px) {
		float: none;
		margin: 0 auto;
	}

    @media screen and (max-width: 610px) {
    	width: 270px;

    	ul {
			li {
				height: auto;

				a {
					height: auto;

					img {
						display: none;
					}

					.post {
						top: 0;
						bottom: auto;
						transform: translateY(0%);
					}
				}
			}
		}
    }	

	li:first-child {
		height: 270px;
		padding: 0 15px;

		@media screen and (max-width: 610px) {
			height: 160px;
		}
	}

		.post--title {
			background-image: url('../img/svg/svg--arrow.svg');
			background-position: right center;
			background-repeat: no-repeat;
			background-size: 7px 10px;
		}

	.all {
		width: auto;
		height: auto;
		position: fixed;
		bottom: 0;
		left: 50%;
		z-index: 3;
		background-color: #fff;
		text-decoration: none;
		padding: 12px 20px 9px;
		transform: translate(-50%, 0);
		font-family: $dosis;
		color: #000;
		box-shadow: 0px 0px 5px 1px #ccc;
		transition: all .3s ease-in-out;

		&.is-checked {
			transform: translate(-50%, 100%);
			box-shadow: none;
		}
	}

	.social {
		width: 153px;
		height: 40px;
		margin: 0 auto;
		
		&--link {
			width: 40px;
			height: 40px;
			display: inline-block;
			border: 1px solid #000;
			margin: {
				left: 4px;
				right: 4px;
			}

			&__facebook {
				background-image: url('../img/svg/svg--social-facebook.svg');
				background-repeat: no-repeat;
			}

			&__twitter {
				background-image: url('../img/svg/svg--social-twitter.svg');
				background-repeat: no-repeat;
			}

			&__instagram {
				background-image: url('../img/svg/svg--social-insta.svg');
				background-repeat: no-repeat;
			}
		}
	}
}

.latest {
	width: 560px;
	margin: 20px 10px;

	@media screen and (max-width: 1199px) {
		float: none;
		margin: 10px auto 18px;
	}

	@media screen and (max-width: 610px) {
		width: 270px;
	}

	a {
		width: 560px;
		height: 560px;

		@media screen and (max-width: 610px) {
			width: 270px;
			height: auto;
		}

		.post {
			@include clearfix;
			
			@media screen and (max-width: 610px) {
				transform: translateY(0);
				position: static;
			}

			&--title {
				clear: both;
				margin-top: 35px;

				p {
					text-transform: none;
					font-size: 18px;
				}
			}
		}
	}
}

.posts {
	margin-top: 0;
	padding-top: 10px;
	border-top: 1px solid #e1e1e1;
	float: none;
	clear: both;

	@media screen and (max-width: 1199px) {
		width: 580px;
		margin: 10px auto;
	}

	@media screen and (max-width: 610px) {
		width: 270px;
	}

	ul {
		li {
			a {
				.post {
					&--title {
						p {
							text-transform: none;
						}
					}
				}
			}
		}
	}
}

.footer {
	font-family: $dosis;
	text-align: center;
	font-size: 12px;
	padding: 20px 0;
}