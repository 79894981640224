@mixin clearfix {
	&:after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin bp($point) {
    @media screen and (min-width: $point) {
    	@content; 
    }
}